import SuccessImg from "../../assets/images/mail-sent_92x85.png";
import React from "react";
import { Link } from "react-router-dom";
import { useRef,useEffect } from "react";

const SuccessForm = () => {
   const formRef = useRef(null);

    useEffect(() => {
         formRef.current.scrollIntoView({behavior: 'smooth'});
    }, []);
 

    return ( 
        <div className="row justify-content-center text-center form-section">
            <div className="row w-50 align-content-center justify-content-center" ref={formRef}>
                <img className="col-3" alt="envelope with green check mark" src={SuccessImg}/>
                <h2>Message Successfully Sent</h2>
                <p className="font-secondary h6Font">Thanks for filling out our form! Someone will reach out to you as soon as possible.</p>
                <div className="col-lg-12 pt-4"><Link className="btn btn-primary rounded-pill w-100" to="/">Close</Link></div>
            </div>
        </div>
    );
}
 
export default SuccessForm 
import React from 'react';
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
import CompanyLogo from '../assets/images/logos/Katies Hallmark.jpg';
const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/Katieshallmark', label: 'Katies Hallmark' }
]

const CompanyKaties = () => {
  return (
    <>
        <Banner title="Katies Hallmark" image="banner-company-sm" />
        <div className='container' style={{minHeight:"50rem"}}>
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill no-wrap h6Font col-auto" to="/PortfolioCompanies"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>
                <div className="row gap-5">
                    <div className="col-lg-8">
                        <img className="py-3 col-lg-2" src={CompanyLogo}/>                
                        <div className="h5Font">Retail Operation</div>
                          <p className="pt-2 h6Font font-secondary text-dark">The purpose of Katie's Hallmark is to inspire meaningful connections which enhance relationships and enrich lives.

                              Our knowledgeable and helpful staff will assist customers in recognizing those everyday little moments that are worth remembering.

                              At Katie's Hallmark, it is not just about holidays, milestones and big dates on the calendar, for us at Katie's it's all about the moments between the milestones!</p>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <div className='h6Font font-secondary'>1986</div>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                6640 S Cicero Avenue <br/>
                                Bedford Park, IL 60638
                            </p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                              <a className="btn-link" href="tel:8003420631">708-425-0552</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                              <a className='btn-link text-start' href={process.env.REACT_APP_KATIES_URL}>www.katieshallmarkoaklawn.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyKaties
import React from 'react'

/**
 * @param dataObject (required) -> oject includes icon and header  
 * @param header (required)
 * @param layout
 * @param iconSize
 *                list-icon-sm : 4.75rem x 4.25rem
 *                list-icon-lg: 7.25 x 7.25rem
 *                default: 6.25rem x 5.5rem
 *                Note: When you pass in the size it effects
 *                      ALL list items. They will have the same icon width x height.
 */
export const ListIcon = (props) => {
    return (
        <ul className={`list-icon-body list-no-indent ${props.layout}`}>
            {
                props?.dataObject.map((item, key) => {
                    return (
                        <li key={key}>
                            <div className="list-icon">
                                <img className={props?.iconSize} src={item.icon} />
                                <div className="list-icon-header" dangerouslySetInnerHTML={{ __html: item.header }}></div>
                            </div>
                            {
                                item?.paragraph && <p>{item.paragraph}</p>
                            }
                        </li>
                    )
                })
            }
        </ul>
    )
}


/**
 * @param dataObject (required) -> oject includes icon and header  
 * @param header (required)
 * @param layout determines how the unordered list will be layed out in its container
 * @param iconSize
 *                list-icon-sm : 4.75rem x 4.25rem
 *                list-icon-lg: 7.25 x 7.25rem
 *                default: 6.25rem x 5.5rem
 *                Note: When you pass in the size it effects
 *                      ALL list items. They will have the same icon width x height.
 */
export const ListIconBullet = (props) => {

    return (
        <ul className={`${props.layout} list-no-indent`}>
            {
                props.dataObject.map((item, key) => {
                    return (
                        <li className="list-icon-bullet" key={key}>
                            <div className="d-flex justify-content-center">
                                <img className={props?.iconSize} src={item.icon} />
                            </div>
                            <div className="list-icon-header">{item.header}</div>
                            {
                                item.bulletPoints && (
                                    <ul className="list-no-indent">
                                        {
                                            item.bulletPoints.map((item, key) => {
                                                return <li key={key} className="list-bulletpoint">
                                                    {item.footerImage ? <div>
                                                        <div dangerouslySetInnerHTML={{ __html: item.bullet }}></div>
                                                        <br />
                                                        <div className='w-auto h-auto'><img src={item.footerImage.ImagePostal} /></div>
                                                    </div>
                                                        : <div dangerouslySetInnerHTML={{ __html: item.bullet }}></div>}
                                                </li>
                                            })
                                        }
                                    </ul>
                                )
                            }
                        </li>
                    )
                })
            }
        </ul>
    )
}




/**
 * @param dataObject (required) -> oject includes icon and header  
 * @param header (required)
 * @param layout determines how the unordered list will be layed out in its container
 * @param iconSize
 *                list-icon-sm : 4.75rem x 4.25rem
 *                list-icon-lg: 7.25 x 7.25rem
 *                default: 6.25rem x 5.5rem
 *                Note: When you pass in the size it effects
 *                      ALL list items. They will have the same icon width x height.
 */
export const ListOrdered = (props) => {

    return (
        <>
            {props.header && <h2 className="pb-2">{props.header}</h2>}
            <ol className={`${props.layout} list-no-indent`}>
                {
                    props.dataObject.map((item, key) => {
                        return (
                            <li className="list-icon-bullet" key={key}>
                                {
                                    item.bulletPoints && (
                                        <ol className="list-no-indent">
                                            {
                                                item.bulletPoints.map((item, key) => {
                                                    return <li key={key} className="list-numbered-body">
                                                        <div className="grid-col-2-auto-1fr" >
                                                            <div className="grid-row-span-2">
                                                                <div className="list-number-custom">{key + 1}</div>
                                                            </div>
                                                            <div className="grid-row-1">
                                                                <div className="list-numbered-header">Header</div>
                                                                <p className="h5Font font-secondary text-dark">Sample</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                })
                                            }
                                        </ol>
                                    )
                                }
                            </li>
                        )
                    })
                }
            </ol>
        </>
    )
}


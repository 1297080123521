import React from 'react'
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
import CompanyLogo from '../assets/images/logos/texasrangermga_leftaligned_118x72.svg';
import { Banner } from '../components/Banner';

const items = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/TXRcompany', label: 'Texar Range MGA' }
]

const CompanyTXR = () => {
  return (
    <>
        <Banner title="Texar Range MGA" image="banner-company-sm" />
        <div className='container'>
            <Breadcrumb>
                {
                    items.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill no-wrap h6Font col-auto" to="/PortfolioCompanies"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>        
                <div className="row gap-5">
                    <div className="col-lg-8">
                        <img className="py-3 col-lg-2" src={CompanyLogo}/>                
                        <div className="h5Font">Managing General Agency</div>
                        <p className="pt-2 h6Font font-secondary text-dark">
                            Texas Ranger MGA (Texas Ranger) is a proud member of the Warrior Insurance Network (WIN), which encompasses a multitude of insurance companies and agencies. Since 1980 WIN's member companies have been involved in the underwriting and sales of auto insurance, as well as life, health ancillary insurance products and have a full-line P&C insurance products through a network of various insurance carriers. Texas Ranger was created to expand WIN's presence in the Texas market, although the organization has operated in the Texas market for last 15 year.
                            <br/><br/>
                            Texas Ranger MGA offer United Security Insurance Company's (USIC) Texas Ranger Personal Auto Insurance Program, which is designed to provide flexibility within underwriting, pricing, and claims service all of which will help enable insurance agencies to sell and make money in the standard auto insurance lines. 
                            <br/><br/>
                            The company is currently looking to further its imprint within Texas with the USIC Texas Ranger Personal Auto Insurance Program, which provides physical damage coverage based on the vehicle's value today rather than using the traditional ISO symbol rating. As such, we are currently looking for independent insurance agency partners who are both professional and consumer friendly. 
                            <br/><br/>
                            The company's objective is to align themselves with agencies known for their customer service, as well as remaining committed to providing innovative and competitively priced products
                        </p>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <p className='h6Font font-secondary'>1980</p>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                Texas Ranger Managing General  <br/>
                                Agency PO Box 388259 Chicago, IL 60638-8259
                            </p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Licensed States</div>
                            <p className='h6Font font-secondary'>TX</p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                            <a className='btn-link' href="tel:8442811605">844-281-1605</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                            <a className='btn-link text-start' href={process.env.REACT_APP_TXR_URL}>www.texasrangermga.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyTXR
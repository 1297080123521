import React from 'react'
import Breadcrumb from '../components/Breadcrumbs';
import { Link } from 'react-router-dom';
import MasterContactUs from '../components/forms/contact-us/MasterContactUs';
import {MultiUseCard} from "../components/Cards";
import IconBuilding from "../assets/images/building_30x30.png";
import IconPhone from "../assets/images/phone_30x30.png";
import {useCoreContext} from "../utils/CoreProvider";
import {CustomTooltip} from "../components/ToolTip";
import { Banner } from '../components/Banner';

const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/ContactUs', label: 'Contact Us' }
]

const companyHours = [
    {day: 'Mon', hours: '8:30am - 5:00pm'},
    {day: 'Tue', hours: '8:30am - 5:00pm'},
    {day: 'Weds', hours: '8:30am - 5:00pm'},
    {day: 'Thur', hours: '8:30am - 5:00pm'},
    {day: 'Fri', hours: '8:30am - 5:00pm'},
    {day: 'Sat', hours: '8:30am - 5:00pm'},
    {day: 'Sun', hours: '8:30am - 5:00pm'},
]

const ContactUs = () => {

  const dbResponse = useCoreContext(); 
  

  return (
      <>
        <Banner title="Contact Us" image="banner-contact-us" />
        <div className="container pb-5">
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <MasterContactUs />
            <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="grid-column-2">
                        <MultiUseCard size="" extrastyle="border-info" title="Phone" imgstart={IconPhone}>
                            <div className="py-3">
                                <a className="btn btn-primary btn-sm rounded-pill" href={`tel:${dbResponse.AppCore?.rawPhoneMain}`}>{dbResponse.AppCore?.phoneMain}</a>
                            </div><br/>
                        </MultiUseCard>                    
                        <MultiUseCard size="" extrastyle="border-info" title="Corporate Office" imgstart={IconBuilding}>
                            <div className="flex justify-content-center w-50 pt-4 font-secondary text-dark h5Font">
                                  6640 S. Cicero Avenue, <br/>Bedford Park, IL 60638
                            </div>
                        </MultiUseCard>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default ContactUs
import React, { useState, useEffect } from "react";
import Breadcrumb from "../components/Breadcrumbs";
import { Banner } from '../components/Banner';
import { Link } from "react-router-dom";
import $ from "jquery";
const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/TermsofUse', label: 'Terms of Use' }
]
const TermsOfUse = () => {
    const [DBResponse, setDBResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if(DBResponse){
            setIsLoading(true)
        }else{
            setIsLoading(false);
        }
    }, []);

    $.ajax({
        type: "POST",
        url: "/AppConfig/GetLegalTermMessage",
        contentType: "application/json; charset=utf-8",
        dataType: "json",
        success: function (result) {
            setDBResponse(result.res.html);
        },
        failure: function (response) {
            console.log("Privacy Policy Page ERROR:" + response);
        },
    });
  return (
    isLoading ? (
        <div className="d-flex justify-content-center loader-section text-primary">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    ):
    <>
        <Banner title="Terms of Use" image="banner-legal" />
        <div className="container pb-5">
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div>
                {DBResponse}
            </div>
        </div>
    </>
  )
}

export default TermsOfUse
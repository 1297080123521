import standardAutoIcon from "./icons/WINicon_Standardauto32x32px.png";
import starStandardAutoIcon from "./icons/WIN_star_dropshadow_standardauto.png";
import starTaxiIcon from "./icons/WIN_star_dropshadow_taxi.png";
import taxiIcon from "./icons/Winicon_Taxi32x32px.png";
import preferredAutoIcon from "./icons/WINicon_PrefAuto_32x32px.png";
import starPreferredAutoIcon from "./icons/WIN_star_dropshadow_preferredauto.png";
import starCommercialAutoIcon from "./icons/WIN_star_dropshadow_commercialauto.png";
import commercialAutoIcon from "./icons/WINicon_Commauto_32x32px.png";
import artisianIcon from "./icons/WINicon_Artisan32x32px.png";
import starArtisianIcon from "./icons/WIN_star_dropshadow_artisanliability.png";

export const usjsconfig = {
  "usjs1":{
    "hover": "ALABAMA",//info of the popup
    "url": "#",//link to any webpage
    "target": "none",// use "new_window", "same_window", "modal", or "none"
    "upColor": "#c4e3f3",//default color
    "overColor": "#cbe600",//highlight color
    "downColor": "#cae9af",//clicking color
    "active": false//true/false to activate/deactivate
  },
  "usjs2":{
    "hover": "ALASKA",
    "url": "#", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#d9ff66", "downColor": "#cae9af",
    "active": false
  },
  "usjs3":{
    "hover": `<b>ARIZONA</b><br><img src=${starStandardAutoIcon} /> `,
    "url": "#", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs4":{
    "hover": `<b>ARKANSAS</b><br><img src=${standardAutoIcon}  />`,
    "url": "#", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs5":{
    "hover": "CALIFORNIA",
    "url": "#", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs6":{
    "hover": `<b>COLORADO</b><br /><img src=${starStandardAutoIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs7":{
    "hover": "CONNECTICUT",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs8":{
    "hover": "DELAWARE",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs9":{
    "hover": "FLORIDA",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs10":{
    "hover": `<b>GEORGIA</b><br /><img src=${starStandardAutoIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#505050", "downColor": "#505050",
    "active": true
  },
  "usjs11":{
    "hover": "HAWAII",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs12":{
    "hover": "IDAHO",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs13":{
    "hover": `<b>ILLINOIS</b><br><img src=${preferredAutoIcon}  /><img src=${standardAutoIcon}  /><img src=${taxiIcon}  /><br><img src=${commercialAutoIcon}  /><img src=${artisianIcon}  />`,
    "url": "#", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs14":{
    "hover": `<b>INDIANA</b><br><img src=${preferredAutoIcon}  /><img src=${standardAutoIcon}  /><img src=${taxiIcon}  /><br><img src=${commercialAutoIcon}  /><img src=${artisianIcon}  />`,
    "url": "#/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs15":{
    "hover": `<b>IOWA</b><br><img src=${standardAutoIcon}  /><img src=${artisianIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs16":{
    "hover": `<b>KANSAS<b/><br><img src=${standardAutoIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs17":{
    "hover": "KENTUCKY",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs18":{
    "hover": `<b>LOUISIANA</b><br><img src=${taxiIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs19":{
    "hover": "<b>MAINE</b>",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#d9ff66", "downColor": "#cae9af",
    "active": false
  },
  "usjs20":{
    "hover": "MARYLAND",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": false
  },
  "usjs21":{
    "hover": "MASSACHUSETTS",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs22":{
    "hover": "MICHIGAN",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs23":{
    "hover": "MINNESOTA",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs24":{
    "hover": "MISSISSIPPI",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs25":{
    "hover": `<b>MISSOURI</b><br><img src=${standardAutoIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs26":{
    "hover": "MONTANA",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs27":{
    "hover": "<b>NEBRASKA</b><br>",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": false
  },
  "usjs28":{
    "hover": `<b>NEVADA</b><br /><img src=${starStandardAutoIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs29":{
    "hover": "NEW HAMPSHIRE",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs30":{
    "hover": `<b>NEW JERSEY</b><br><img src=${taxiIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs31":{
    "hover": "<b>NEW MEXICO</b>",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#d9ff66", "downColor": "#cae9af",
    "active": false
  },
  "usjs32":{
    "hover": "NEW YORK",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs33":{
    "hover": "NORTH CAROLINA",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs34":{
    "hover": "NORTH DAKOTA",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs35":{
      "hover": `<b>OHIO</b><br /><img src=${taxiIcon}  /><img src=${standardAutoIcon}>`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs36":{
    "hover": `<b>OKLAHOMA</b><br><img src=${standardAutoIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "same_window",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs37":{
    "hover": "OREGON",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs38":{
    "hover": `<b>PENNSYLVANIA</b><br><img src=${standardAutoIcon}  /><img src=${taxiIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs39":{
    "hover": "RHODE ISLAND",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs40":{
    "hover": "SOUTH CAROLINA",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs41":{
    "hover": "SOUTH DAKOTA",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs42":{
    "hover": `<b>TENNESSEE</b<br /><img src=${starStandardAutoIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#505050", "downColor": "#505050",
    "active": true
  },
  "usjs43":{
    "hover": `<b>TEXAS</b><br><img src=${standardAutoIcon} /><img src=${commercialAutoIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#505050", "downColor": "#505050",
    "active": true
  },
  "usjs44":{
    "hover": "UTAH",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs45":{
    "hover": "VERMONT",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs46":{
    "hover": `<b>VIRGINIA</b<br /><img src=${starCommercialAutoIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs47":{
    "hover": "WASHINGTON",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs48":{
    "hover": "WEST VIRGINIA",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
      "upColor": "#505050", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs49":{
    "hover": `<b>WISCONSIN</b><br><img src=${standardAutoIcon}  /><img src=${taxiIcon}  />`,
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#505050", "overColor": "#44a4dc", "downColor": "#cae9af",
    "active": true
  },
  "usjs50":{
    "hover": "WYOMING",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "usjs51":{
    "hover": "WASHINGTON DC",
    "url": "https://www.html5interactivemaps.com/", "target": "none",
    "upColor": "#c4e3f3", "overColor": "#cbe600", "downColor": "#cae9af",
    "active": false
  },
  "general":{
    "borderColor": "#FFFFFF",
    "visibleNames": "#FFFFFF",
    "lakesFill": "#FFFFFF",
    "lakesOutline": "#FFFFFF"
  }
};

//border was 9CA886
//"lakesFill": "#C5E8FF",
//"lakesOutline": "#9CA8B6"
//"visibleNames": "#adadad",
import React from 'react';
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
import CompanyLogo from '../assets/images/logos/USIC Logo 4C.jpg';
const items = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/USICcompany', label: 'United Security Insurance Company' }
]

const CompanyUSIC = () => {
  return (
    <>
        <Banner title="United Security Insurance Company" image="banner-company-sm" />
        <div className='container'>
            <Breadcrumb>
                {
                    items.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill no-wrap h6Font col-auto" to="/"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>        
                <div className="row gap-5">
                    <div className="col-lg-8">
                        <img className="py-3 col-lg-2" src={CompanyLogo}/>                
                        <div className="h5Font">Insurance Carrier</div>
                        <p className="pt-2 h6Font font-secondary text-dark">United Security Insurance Company (USIC), formally known as United Security Health and Casualty (USH&C) is a member of the Warrior Insurance Network (WIN). USIC celebrated its 50th anniversary in 2023 and specializes in providing affordable Personal Lines Auto Insurance products with multiple discounts available, responsive claims handling and quality customer service.</p>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <p className='h6Font font-secondary'>1980</p>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                6640 S Cicero Avenue <br/>
                                Bedford Park, IL 60638
                            </p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Licensed States</div>
                            <p className='h6Font font-secondary'>AR AZ GA IL IN MO NE<br/>OK TX</p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                            <a className="btn-link" href="tel:8008754422">800-875-4422</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                            <a className='btn-link text-start' href={process.env.REACT_APP_USIC_URL}>www.unitedsecurityins.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyUSIC
import React from 'react';
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
import CompanyLogo from '../assets/images/logos/insureonline_230x106.svg';
const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/InsureOnline', label: 'InsureOnline' }
]

const CompanyIO = () => {
  return (
    <>
        <Banner title="InsureOnline" image="banner-company-sm" />
        <div className='container' style={{minHeight:"50rem"}}>
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill no-wrap h6Font col-auto" to="/PortfolioCompanies"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>
                <div className="row gap-5">
                    <div className="col-lg-8">
                        <img className="py-3 col-lg-2" src={CompanyLogo}/>                
                        <div className="h5Font">Agency</div>
                        <p className="pt-2 h6Font font-secondary text-dark">
                            InsureOnline.com is a direct-to-consumer online insurance rater backed by a team of multilingual agents who are licensed to quote and bind auto, health, home, and other insurance policies. InsureOnline partners with the best regional and national insurance carriers.
                            <br/><br/>
                            The goal of InsureOnline.com is to provide consumers with the absolute best pricing option on a variety of different insurance products by utilizing a simple, safe, and user-friendly online rating system powered by leading insurance carriers across the country.</p>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <div className='h6Font font-secondary'>1980</div>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                6640 S Cicero Avenue <br/>
                                Bedford Park, IL 60638
                            </p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                            <a className="btn-link" href="tel:8443585605">844-358-5605</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                            <a className='btn-link text-start' href={process.env.REACT_APP_IO_URL}>www.insureonline.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyIO
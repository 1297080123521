import React, { useEffect, useState } from "react";
import { Card, CardInfo } from "../components/Cards";
import { useCoreContext } from "../utils/CoreProvider";
import { HeroBanner } from "../components/Banner";
import { Link } from "react-router-dom";
import { ListIcon } from "../components/ListIcon";
import IconSustain from "../assets/images/sustainability_255x177.png";
import IconCompetetive from "../assets/images/competitive-products_255x177.png";
import IconNetwork from "../assets/images/our-network_255x177.png";
import IconAgency from "../assets/images/managing-general-agencies_100X90.png";
import IconInsurance from "../assets/images/insurance-companies_100X90.png";
import IconServices from "../assets/images/other-service-companies_100X90.png";
import ImageComputer from "../assets/images/guy-typing-on-computer_660X451.svg";
import LonestarLogo from "../assets/images/logos/logo_lonestar_230x106.svg";
import FCICLogo from "../assets/images/logos/logo_firstchicago_230x106.svg";
import TXRLogo from "../assets/images/logos/texasranger_230x106.svg";
import USICLogo from "../assets/images/logos/USIC Logo 4C.jpg";
import RRLogo from "../assets/images/logos/WV NEW LOGO.png";

const listSample = [
    { icon: IconAgency, header: 'MANAGING GENERAL <br/> <span class="text-warning"> AGENCIES </span>' },
    { icon: IconInsurance, header: 'INSURANCE <br/> <span class="text-warning">COMPANIES</span>' },
    { icon: IconServices, header: 'OTHER <span class="text-warning">SERVICE <br/>COMPANIES</span>' }
]

export const Home = () => {

    const [Render, setRender] = useState();
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const dbResponse = useCoreContext();

    useEffect(() => {
        setRender(!Render)
    }, []);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    return (
        <>
            <HeroBanner title="Warriors Unite" subtitle="Warrior Invictus and its affiliated companies serve thousands of customers throughout the United States. Discover Warrior Invictus' diverse portfolio." />
            <div className="bg-white pt-6 pb-5">
                <div className="container">
                    <div className="row p-1 align-items-center text-center">
                        <h2>About Us</h2>
                        <p>Warrior Invictus Holding Company, Inc. (Warrior Invictus) is a holding <br /> company that has supported and held many different entities over the year including&nbsp;  
                            <a className="pFont no-wrap" href={process.env.REACT_APP_FCIC_URL} target="_blank">First Chicago Insurance Company</a>, <a className="pFont no-wrap" href={process.env.REACT_APP_USIC_URL} target="_blank">United Security Insurance Company</a>,&nbsp;
                            <a href={process.env.REACT_APP_RR_URL} className="pFont no-wrap" target="_blank">West Virginia National Auto Insurance</a>,&nbsp;
                            <a className="pFont no-wrap" href={process.env.REACT_APP_LS_URL} target="_blank">Lonestar MGA</a>,&nbsp;<a className="no-wrap pFont" href={process.env.REACT_APP_TXR_URL} target="_blank">Texas Ranger MGA</a>:</p>
                        <div className="grid-col-3-lg-card px-5">
                            <Card size="card-lg" type="card-basic-detail" title="STAYING POWER" body="Growing and supporting our portfolio of companies since 1980." image={IconSustain} />
                            <Card size="card-lg" type="card-basic-detail" title="INNOVATIVE STRATEGY" body="Maximizing resources to give subsidiaries a competitive edge." image={IconCompetetive} />
                            <Card size="card-lg" type="card-basic-detail" title="DIVERSE PORTFOLIO" body="Investing in a diverse range of businesses in insurance services, marketing, and more." image={IconNetwork} />
                        </div>
                    </div>
                    <div className="row justify-content-center px-4">
                        <a className="btn btn-primary rounded-pill col-lg-3 col-8" href="/AboutUs">Learn More</a>
                    </div>
                </div>
            </div>
            <div className="bg-white pt-6 pb-5">
                <dib>
                    <div className="bg-dark text-white">
                        <div className="container" id="AboutUs">
                            <div className="grid-padding-lg">
                                <div>
                                    <h2 className="text-white font-primary-medium">Our Vision</h2><br />
                                    <p className="text-white">Warrior Invictus was founded in 1980. This holding company is working hard to be regionally recognized and respected as a leader in the insurance industry by consistently exceeding customer expectation through exceptional service, and innovative marketing.</p>
                                </div>
                                <div className="available-space">
                                    <img src={ImageComputer} className="offcentered-image available-space w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </dib>
            </div>
            <div className="bg-white pt-6 pb-5 px-3">
                <div className="container">
                    <div className="row align-items-center gap-5">
                        <div className="col-lg-6">
                            <h2>Our Companies</h2>
                            <p className="h5Font text-dark font-secondary">We invite you to learn more about the different companies of Warrior Invictus and the various entities that contribute to this growing organization.</p>
                            <div className="row gap-3 py-4">
                                <Link className="btn btn-secondary rounded-pill col-lg-10" to="/PortfolioCompanies">Learn more about the entities of Warrior Invictus</Link>
                            </div>
                        </div>
                        <div className="col-lg-5 bg-light pt-5 list-padding">
                            <ListIcon iconSize="list-icon-sm" layout="grid grid-row-1" dataObject={listSample} /><br />
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-light pt-5">
                <div className="container text-center pb-5">
                    <h2>About Warrior Invictus Member Companies</h2>
                    <p>WIN's member companies are licensed in 22 states which include <b>Arizona, Arkansas, Colorado, Georgia, Illinois, Indiana, Iowa, Kansas, Louisiana, Maryland, Missouri, Nebraska, Nevada, New Jersey, Ohio, Oklahoma, Pennsylvania, Tennessee, Texas, Virginia, West Virginia, and Wisconsin.</b></p>
                    <p>Click below to learn more about the companies</p>
                    <div className={windowSize.innerWidth < 767.98 ? `grid-fixed-two-by-two` : `grid-column-5`}>
                        <Card size="card-company-padding card-company-height" bgcolor="bg-white py-5" type="card-link" image={FCICLogo} path={process.env.REACT_APP_FCIC_URL} />
                        <Card size="card-company-padding card-company-height" bgcolor="bg-white py-5" type="card-link" image={USICLogo} path={process.env.REACT_APP_USIC_URL} />
                        <a className={`card card-link bg-white `} href={process.env.REACT_APP_RR_URL}>
                             <div className={`card-image w-100 card-wvnai-logo`} />
                        </a>
                        <Card size="card-company-padding card-company-height" bgcolor="bg-white py-5" type="card-link" image={TXRLogo} path={process.env.REACT_APP_TXR_URL} />
                        <Card size="card-company-padding card-company-height" bgcolor="bg-white py-5" type="card-link" image={LonestarLogo} path={process.env.REACT_APP_LS_URL} />
                    </div>
                    <br/>
                    <p>Warrior Invictus is headquartered at 6640 S. Cicero Avenue, Bedford Park, IL, 60638. </p>
                </div>
            </div>
            <div className="bg-white pb-1">
                <hr />
                <div className="container">
                    <div className="row align-items-center py-4">
                        {
                            windowSize.innerWidth < 767.98 ? (
                                <>
                                    <div className="col-lg-9">
                                        <div className="row justify-content-center py-4">
                                            <div className="girl-computer-icon justify-content-center"><img /></div>
                                        </div>
                                        <div className="card-body">
                                            <div className="grid-row-1 text-center">
                                                <div className="h5Font font-primary-semibold text-dark"> Get in touch and let us know how we can help. </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="justify-content-center py-4">
                                        <Link className="btn btn-primary rounded-pill w-100" to="/ContactUs">Contact Us</Link>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="col-lg-9">
                                        <div className="grid-col-2-auto-1fr align-items-center gap-3">
                                            <div className="girl-computer-icon"><img /></div>
                                            <div className="card-body">
                                                <div className="grid-row-1 text-start">
                                                    <div className="h5Font text-dark">Get in touch and let us know how we can help.</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 d-flex justify-content-end ml-auto">
                                        <Link className="btn btn-primary rounded-pill h6Font w-100" to="/ContactUs">Contact Us</Link>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

import React from 'react';
import { Banner } from '../components/Banner';
import Breadcrumb from "../components/Breadcrumbs";
import { Link } from 'react-router-dom';
import CompanyLogo from '../assets/images/logos/preferredhealthinsurancesolutions_leftaligned_188x72.svg';
const crumbs = [
    { to: '/', label: 'Home' },
    { to: '/PortfolioCompanies', label: 'Portfolio Companies' },
    { to: '/PHIS', label: 'Preferred Health Insurance Solutions' }
]

const CompanyPHIS = () => {
  return (
    <>
        <Banner title="Preferred Health Insurance Solutions" image="banner-company-sm" />
        <div className='container' style={{minHeight:"50rem"}}>
            <Breadcrumb>
                {
                    crumbs.map(({to, label}) =>(           
                        <Link key={to} to={to}>{label}</Link>
                    ))
                }
            </Breadcrumb>
            <div className="row pb-5">
                <div className="col-auto pb-4">
                    <Link className="btn btn-light btn-xs rounded-pill no-wrap h6Font col-auto" to="/PortfolioCompanies"><i className="fa-solid fa-chevron-left"></i>&nbsp;Go Back</Link>
                </div>
                <div className="row gap-5">
                    <div className="col-lg-8">
                        <img className="py-3 col-lg-2" src={CompanyLogo}/>                
                        <div className="h5Font">Agency</div>
                          <p className="pt-2 h6Font font-secondary text-dark">Preferred Health Insurance Solutions (PHIS) is a national health enrollment firm that offers a variety of health products for individuals and their families. Staffed with multi-lingual, licensed and certified health insurance agents that specialize in Major Medical Health products on the federal and state exchanges. Contracted with national and regional insurance carriers across the country, allows us to shop multiple carries and coverage options. We work with consumers to determine their subsidy eligibility, review benefits and help them enroll in the plan that will meet their individual healthcare needs. Also offering a choice of other affordable health products such as: Short Term Medical, Critical Illness, Dental Plus Vision and Hearing, Personal Accident Medical, Telemedicine and many more. Preferred Health Insurance Solutions - providing Quality Products from Caring Professionals since 1986.</p>
                    </div>
                    <div className="col">
                        <div className="row">
                            <div className="h5Font text-primary">Year Founded</div>
                            <div className='h6Font font-secondary'>1980</div>
                        </div>
                        <div className="row pt-3">
                            <div className="h5Font text-primary">Headquarters</div>
                            <p className='h6Font font-secondary'>
                                6640 S Cicero Avenue <br/>
                                Bedford Park, IL 60638
                            </p>
                        </div>
                        <div className="row pb-3">
                            <div className="h5Font text-primary">Contact Info</div>
                            <a className="btn-link" href="tel:8003420631">800-342-0631</a>
                        </div>
                        <div className="row">
                            <div className="h5Font text-primary">Company Website</div>
                            <a className='btn-link text-start' href={process.env.REACT_APP_PHIS_URL}>www.phisonline.com</a>
                        </div>
                    </div>
                </div>
            </div><br/><br/>
        </div>
    </>
  )
}

export default CompanyPHIS
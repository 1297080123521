import React from 'react'
import { useState, useEffect } from "react";
import validateInfo from "../Validation.js"
import $ from "jquery";

const ContactUsForm = (props) => {
    const [FormErrors, setFormErrors] = useState(1);
    const [IsSubmitting, setIsSubmitting] = useState(false);
    const [Attempt, setAttempt] = useState(false);

    useEffect(() => {
        if (FormErrors === "Ready" && IsSubmitting) {
            props.submitForm();
            $.ajax({
                type: "POST",
                url: "/Email/SendContactFormEmail",
                data: JSON.stringify(props.state, function (k, v) { return v === undefined ? "" : v; }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                failure: function (response) {
                    alert(response.d);
                }
            });
        }
    }, [FormErrors, IsSubmitting]);
    useEffect(() => {
        if (Attempt === true) {
            setFormErrors(validateInfo(props.state));
            if (FormErrors > 1) {
                setIsSubmitting(false);
            } else if (FormErrors <= 0) {
                setFormErrors("Ready");
            }
        }
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        setAttempt(true);
        setIsSubmitting(true);
    };

    const handleInput = (e) => {
        if (e.key !== 'Backspace' && (e.target.value.length === 3 || e.target.value.length === 7)) {
            e.target.value += '-';
        }
    };

  return (
    <form className="form-section needs-validation" noValidate onSubmit={handleSubmit}>
          <div className="text-center"><h2>Contact Us</h2></div>
          <div className="text-center flex justify-content-center"><h5 className="text-dark font-primary" style={{width:"90%"}}>For general information regarding investment opportunities, biographies of our Executive Management team, or anything else, reach out to us by either our contact us form or phone.</h5></div>
          <br/>
          <div className="col-auto">
              <div className="standout-section py-3 px-4"><span className="asterisk" />Indicates required fields</div>
          </div>
          <br />
        <div className="row pb-2">
            <div className="col-lg-12">
                <label>Topic of Interest<span className="asterisk" /></label>
                <select id="Subject" name="requiredSelect" className="form-select" onChange={props.handleChange}>
                    <option disabled selected>Select</option>
                    <option id="generalInfo" value="General Information">General Information</option>
                    <option id="investors" value="Investors">Investors</option>
                    <option id="other" value="Other">Other</option>
                </select>
            </div>
        </div><br/>
        <div className="row form-inputs-gap pb-2">
            <div className="col-lg-6">
                <label>First Name<span className="asterisk"/></label>
                <input id="FirstName" name="requiredQuestion" type="text" className="form-control" onChange={props.handleChange} maxLength="256" ></input>
            </div>
            <div className="col-lg-6">
                <label>Last Name<span className="asterisk"/></label>
                <input id="LastName" name="requiredQuestion" type="text" className="form-control" onChange={props.handleChange} maxLength="256"></input>
            </div>
        </div><br/>
        <div className="row form-inputs-gap pb-2">
            <div className="col-lg-3">
                <label>Preference of Contact?<span className="asterisk"/></label>
                <select id="ContactType" name="requiredSelect" className="form-select" onChange={props.handleChange}>
                    <option disabled selected>Select</option>
                    <option id="phone" value="phone">Phone</option>
                    <option id="Email" value="Email">Email</option>
                </select>
            </div>
            <div className="col-lg-4">
                <label>Phone<span className={`${(props.state.ContactType === "phone") ? 'asterisk' : ''}`} /></label>
                <input id="PhoneNumber" name="dependentQuestion" type="text" className="form-control" onChange={props.handleChange} type="tel" onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12"></input>
            </div>            
            <div className="col-lg-5">
                <label>Email<span className={`${(props.state.ContactType === "Email") ? 'asterisk' : ''}`}/></label>
                <input id="Email" name="dependentQuestion" type="text" className="form-control" onChange={props.handleChange} maxLength="256"></input>
            </div>
        </div><br/>
        <div>
            <label>Message<span className="asterisk"/></label>
            <textarea id="Message" name="requiredQuestion" className="form-control" onChange={props.handleChange} maxLength="500" placeholder="Enter Message Here"/>
        </div><br/><br/>
        <div className="row justify-content-center px-2">
            <button className="btn btn-primary rounded-pill btn-sm col-lg-8" type="submit">Submit</button>
        </div>
    </form>
  )
}

export default ContactUsForm
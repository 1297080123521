import React, { useState, useEffect } from 'react';

/**
 * @param title (optional)
 * @param subtitle (optional)
 */
export const HeroBanner = (props) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    return (
        windowSize.innerWidth < 767.98 ?
            (
                <>
                    <div className='hero-banner'></div>
                    {
                        props.title && <div className="w-100 py-4 text-center">
                            <h1 className="text-primary">{props.title}</h1>
                            {props.subtitle && <p className="px-2 text-dark">{props.subtitle}</p>}
                        </div>
                    }
                </>
            ) : (
                <>
                    <div className='hero-banner'>
                        {
                            props.title && <div className="w-100">
                                <h1>{props.title}</h1>
                                {props.subtitle && <div className='d-flex justify-content-center'><div className='col-7'><p className="text-white">{props.subtitle}</p></div></div>}
                            </div>
                        }
                    </div>
                </>
            )
    );
};

/**
* @param title (optional)
  @param image (required)
  @param subtitle (optional)
*/
export const Banner = (props) => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    return (
        windowSize.innerWidth < 767.98 ?
            (
                <>
                    <div className={`banner ${props.image}`}></div>
                    {
                        props.title && <div className="w-100 py-4 text-center">
                            <h1 className="text-primary">{props.title}</h1>
                            {props.subtitle && <p className="text-secondary">{props.subtitle}</p>}
                        </div>
                    }
                </>
            ) : (
                <>
                    <div className={`banner ${props.image}`}>
                        {
                            props.title && <div className="w-100">
                                <h1>{props.title}</h1>
                                {props.subtitle && <p className="text-white">{props.subtitle}</p>}
                            </div>
                        }
                    </div>
                </>
            )
    );
};
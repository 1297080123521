import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
        <div className="footer-primary">
            <div className="container">
                  <div className="footer-layout">
                      <Link className="col-auto" to="/PrivacyPolicy">Privacy Policy</Link>
                      <Link className="col-lg-6" to="/TermsofUse">Terms of Use</Link>
                </div>
            </div>
        </div>
        <div className="footer-secondary">
            <div className="container">
                <div className="grid-footer-logo">
                    <div className='footer-company-logo-size company-logo'/>
                    <p>&copy; {(new Date().getFullYear())} All Rights Reserved</p>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer
import React,{useEffect, useState} from 'react'
import { Banner } from '../components/Banner'
import Breadcrumb from '../components/Breadcrumbs'
import { Link } from 'react-router-dom'
import { Card } from '../components/Cards'
import ImageAOP from "../assets/images/logos/atonceprinting_230x106.svg";
import ImageFCIC from "../assets/images/logos/firstchicagoinsurancecompany_230x106.svg";
import ImageIO from "../assets/images/logos/insureonline_230x106.svg";
import ImageLS from "../assets/images/logos/lonestarmga_230x106.svg";
import ImageTXR from "../assets/images/logos/texasrangermga_230x106.svg";
import ImagePHIS from "../assets/images/logos/preferredhealthinsurancesolutions_230x106.svg";
import ImagePRA from "../assets/images/logos/preferredriskadministrators_230x106.svg";
import ImagePRIA from "../assets/images/logos/preferredriskinsuranceagency_230x106.svg";
import ImagePRIS from "../assets/images/logos/preferredriskinsuranceservices_230x106.svg";
import ImageUSIC from "../assets/images/logos/USIC Logo 4C.jpg";
import ImageWIN from "../assets/images/logos/Warrior Insurance Network 2C logo.png";
import ImageVA from "../assets/images/logos/vitalityagency_230x106.svg";
import ImageRR from "../assets/images/logos/WV NEW LOGO.png";
import ImageKaties from "../assets/images/logos/Katies Hallmark.jpg";

const items = [
  { to: '/', label: 'Home' },
  { to: '/PortfolioCompanies', label: 'Portfolio Companies' }
]


const PortfolioCompanies = () => { 
  const [Active, setActive] = useState("ALL");




  function Filter (e){
    e.preventDefault();

    let subject = e.target.value;
 
    setActive(subject);

      const items = document.querySelectorAll(`.ALL`);

      items.forEach((item) => {
          const category = item.classList[1];

          if (subject === category || subject === 'ALL') {
              item.classList.remove('d-none');
          } else {
              item.classList.add("d-none");
          }
      });
  }

  return (
      <>
      <Banner title="Portfolio Companies" image="banner-portfolio"/>
      <div className="container">
        <Breadcrumb>
          {items.map(({to, label}) =>(           
          <Link key={to} to={to}>{label}</Link>
          ))}
        </Breadcrumb>
        <div className="row justify-content-center text-center">
          <div className="col-lg-8">
            <p>Read about the multiple entities that belong to the Warrior Invictus Holding Company Inc.</p>
          </div>
        </div><br/>
        <div className="grid-filter-container row px-2">
            <button className={`btn ${Active === 'ALL' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="ALL" onClick={(e) => {Filter(e)}}>All</button>
            <button className={`btn ${Active === 'AGENCY' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="AGENCY" onClick={(e) => {Filter(e)}}>Agencies</button>
            <button className={`btn ${Active === 'INSURANCE' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="INSURANCE" onClick={(e) => {Filter(e)}}>Insurance Carriers</button>
            <button className={`btn ${Active === 'SERVICE' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs `} value="SERVICE" onClick={(e) => {Filter(e)}}>Service Companies</button>
            <button className={`btn ${Active === 'MGA' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="MGA" onClick={(e) => {Filter(e)}}>Managing General Agency</button>
            <button className={`btn ${Active === 'MARKETING' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="MARKETING" onClick={(e) => {Filter(e)}}>Marketing</button>
            <button className={`btn ${Active === 'OTHER' ? 'btn-primary' : 'btn-light' } rounded-pill btn-xs`} value="OTHER" onClick={(e) => {Filter(e)}}>Other</button>
              </div>
        <div className="row">
            <div className="grid-companies gap-4 pt-3">
                <div className="ALL OTHER"><Card size="h-100 px-4" type="card-link" image={ImageAOP} path="/AtOncePrinting"/></div>
                <div className="ALL INSURANCE"><Card size="h-100 px-4" type="card-link" image={ImageFCIC} path="/FCIC"/></div>
                <div className="ALL AGENCY"><Card size="h-100 px-4" type="card-link" image={ImageIO} path="/InsureOnline"/></div>
                <div className="ALL OTHER"><Card size="h-100 px-4" type="card-link" image={ImageKaties} imgstyle="katies-img-dimension" path="/KatiesHallmark"/></div>
                <div className="ALL MGA"><Card size="h-100 px-4" type="card-link" image={ImageLS} path="/LonestarMGA"/></div>
                <div className="ALL MGA"><Card size="h-100 px-4" type="card-link" image={ImageTXR} path="/TexasRangerMGA"/></div>
                <div className="ALL AGENCY"><Card size="h-100 px-4" type="card-link" image={ImagePHIS} path="/PHIS"/></div>
                <div className="ALL SERVICE"><Card size="h-100 px-4" type="card-link" image={ImagePRA} path="/PRA"/></div>
                {/*<div className="ALL AGENCY"><Card size="h-100 px-4" type="card-link" image={ImagePRIA} path="/PRIA"/></div>*/}
                {/*<div className="ALL SERVICE"><Card size="h-100 px-4" type="card-link" image={ImagePRIS} path="/PRIS" /></div>*/}
                <div className="ALL INSURANCE">
                    <Card size="h-100 px-4" type="card-link" image={ImageRR} imgstyle="ridgerunner-img-dimension" path="/RidgeRunner"/>
                    </div>
                <div className="ALL INSURANCE"><Card size="h-100 px-4" type="card-link" image={ImageUSIC} path="/USIC"/></div>
                <div className="ALL MARKETING"><Card size="h-100 px-4" type="card-link" image={ImageWIN} path="/WIN"/></div>
                <div className="ALL AGENCY"><Card size="h-100 px-4" type="card-link" image={ImageVA} path="/Vitality"/></div>
            </div>
        </div>
      </div>
    </>
  )
}

export default PortfolioCompanies
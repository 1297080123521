import React, { useState, useEffect } from "react";

/**
 * @description This card can return multiple types of a card style depending
 *              on what the props gets passed in.
 * @param type  
 *          card-basic
 *          card-basic-detail
 *          card-link
 *          car-link-detail
 *          card-video
 *          default: card (transparent)
 * 
 * @param size  (optional)
 *                     card-sm,
 *                     card-lg
 *                     .... TBD
 * @param image (optional)
 * @param imgstyle (optional) -> this will manipulate the image 
 * @param title (optional)
 * @param body (optional)
 * @param path (optional) -> refers to the location of where that card will take you,
 *                           if the card is treated as a button.
 * @param shadow (oprional) -> Choose
 *                             shadow
 *                             shadow-sm
 *                             shadow-lg                
 * @param color => background color
 * @param url (optional)     TBD
 * 
 */
export const Card = (props) => {
    const [ToggleArrow, setToggleArrow] = useState(false);
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    return props.type === "card-link" || props.type === "card-link-detail" ? (
        <a className={`card ${props.type} ${props.bgcolor} ${props.shadow} ${props.size}`} href={props.path}>
            {props.image && <img className={`card-image w-100 h-auto ${props.imgstyle}`} src={props.image} />}
            {props.title && <div className="card-header">{props.title}</div>}
            {props.body && <div className="card-body">{props.body}</div>}
        </a>
    ) : props.type === "card-basic-detail" ? (
            <>
                {
                    windowSize.innerWidth < 767.98 ? (
                        <div className={`card ${props.type} ${props.bgcolor} ${props.shadow} ${props.size} justify-content-center`}>
                            {props.image && <img className="card-image" src={props.image} />}
                            {props.title && <div className="card-accordion-title">
                                <div className="card-header">{props.title}</div>
                                {
                                    props.body && <span className="card-btn-accordion">
                                        <button onClick={() => { setToggleArrow(!ToggleArrow) }} >
                                            <i className={`text-secondary fa-solid ${ToggleArrow ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                                        </button>
                                    </span>
                                }
                            </div>
                            }
                            {(props.body && ToggleArrow) && <div className="pt-3">{props.body}</div>}
                            {props.footer && <div className="card-footer">{props.footer}</div>}
                        </div>
                    ) : (
                            <div className={`card ${props.type} ${props.bgcolor} ${props.shadow} ${props.size} justify-content-center`}>
                                {props.image && <img className="card-image" src={props.image} />}
                                {props.title && <div className="card-accordion-title">
                                    <div className="card-header">{props.title}</div>
                                    {
                                        props.body && <span className="card-btn-accordion">
                                            <button onClick={() => { setToggleArrow(!ToggleArrow) }} >
                                                <i className={`fa-solid ${ToggleArrow ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                                            </button>
                                        </span>
                                    }
                                </div>
                                }
                                {props.body && <div className="card-body d-none-mobile">{props.body}</div>}
                                {props.footer && <div className="card-footer">{props.footer}</div>}
                            </div>
                    ) 
                }
        </>
    ):(
        <div className={`card ${props.type} ${props.bgcolor} ${props.shadow} ${props.size} justify-content-center`}>
            {props.image && <img className="card-image" src={props.image} />}
            {props.title && <div className="card-accordion-title">
                <div className="card-header">{props.title}</div>
                    {
                        props.body && <span className="card-btn-accordion">
                            <button onClick={() => { setToggleArrow(!ToggleArrow) }} >
                                <i className={`fa-solid ${ToggleArrow ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
                            </button>
                        </span>
                    }
                </div>
            }
            {props.body && <div className="card-body d-none-mobile">{props.body}</div>}
            {props.footer && <div className ="card-footer">{props.footer}</div>}
        </div>
    );
};

/**
 * @param size (optional)
 *          card-info-sm
 *          card-info-lg
 * 
 * @param title (required)
 * @param body (required)
 * @param path (required) -> refers to the location of where that card will take you,
 *                           if the card is treated as a button.
 * @param url (optional)     TBD
 */
export const CardInfo = (props) => {
    return (
        props.size === "card-info-sm" ? (
            <a className={`card card-info ${props.size} `} href={props.path}>
                <div className="card-arrow"><span className="fa-solid fa-chevron-right" ></span></div>
                <div className="card-title">{props.title}</div>
                <p className="card-body text-start h6Font p-0">{props.body}</p>
            </a>
        ) : (
            <a className={`card card-info ${props.size} `} href={props.path}>
                <div className="card-arrow"><span className="fa-solid fa-chevron-right" ></span></div>
                <div className="card-info-container">
                    <div className=" card-title"> {props.title} </div>
                    <p className="card-body text-start h6Font p-0">{props.body}</p>
                </div>
            </a>
        )
    )
};

/**
 * @param title (required)
 * @param body (required)
 * @param date (optional) 
*/
export function NotificationCard(props) {
    return (
        <div className={`card card-notification`}>
            <div className="grid-col-2-auto-1fr gap-2">
                <div className="grid-row-span-2 card-notification-icon"/>
                <div className="card-body">
                    {
                        (props.title || props.data) && (
                        <div className="grid-row-1 pb-3">
                            {props.title !== "" && <h3 className="card-title"> {props.title} </h3> }
                            {props.date && <span className="d-flex text-start">Event Date: <span className="text-primary"> {props.date} </span></span> }
                        </div>
                        )
                    }
                    <div className={`grid-row-1 card-text ${props.bodyalign}`}>{props.body}</div>
                </div>
            </div>
        </div>
    );
}


/**
 * @param thumbnail (required)
 * @param thumbnailhover (required)
 * @param videolink (required)
 */
export const VideoOnlyCard = (props) => {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return(
        <a className="d-flex justify-content-center align-items-center" href={props.videolink} target="_blank" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                {isHovering ? (
                    <img className="w-75 h-75" src={props.thumbnailhover} alt="Card image" />
                ) : (
                    <img className="w-75 h-75" src={props.thumbnail} alt="Card image" />
                )}
        </a>
    )
}

/**
 * @param title (required)
 * @param subtitle (required)
 * @param thumbnail (required)
 * @param thumbnailhover (required)
 * @param videourl (required)
 * @param body (required)
 * @param footerurl (optional)
 * @param footertext (optional)
 */
export function VideoCard(props) {
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };

    return (
        <div className={`card card-video `}>
            <a href={props.videolink} target="_blank" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                {isHovering ? (
                    <img className="card-img-top" src={props.thumbnailhover} alt="Card image" />
                ) : (
                    <img className="card-img-top" src={props.thumbnail} alt="Card image" />
                )}
            </a>
            <div className="card-body">
                {props.title && <div className="card-title">{props.title}</div>}
                {props.subtitle && <div className="card-sub-title">{props.subtitle}</div>}
                <p className="card-text">{props.body}</p>
            </div>
                {props.footerurl && (
                    <div className="card-footer">
                        <a href="#" className="btn btn-secondary btn-sm col-lg-12">{props.footertext}</a>
                    </div>
                )}
        </div>
    );
}



/**
 * @param size 
 *          card-sm
 *          card (default)
 *          card-xlg
 * @param imgtop - Image will be placed at the top of the card
 * @param imgstart - Image will be placed on the same line as the the start
 * @note if imgtop or imgstart is not passed in no image will be displayed 
 * 
 * @param extrastyle - styles you can apply to the card go in this param ex: bg-color, shadows etc
 * @param title (required)
 * @param paragraph (required)
 * @param alignment - if you pass in imgstart you have the option of passing in an alignment class
 *                    for the image + text ex:align-items-center
 * 
 * @param footertext (optional)
 * @param footerurl (optional)
 */
export function MultiUseCard(props) {

    return (
        <div className={`card card-multi-use ${props.extrastyle} ${props.size}`}>
            {props.imgtop ? (
                <>
                    <img className="card-image-sm" src={props.imgtop} alt="Card image" />
                    <div className="card-body">
                        <h3 className="card-title">{props.title}</h3><br /><br />
                        <div className="pFont text-start" dangerouslySetInnerHTML={{ __html: props.paragraph }}></div>
                    </div>
                </>
            ) : props.imgstart ? (

                <>
                    <div className={`d-flex ${props.alignment} gap-3`}>
                        <img className="card-multi-use-img-start" src={props.imgstart} />
                        <div className="h5Font text-primary text-start">{props.title}</div><br /><br />
                        {
                            props.paragraph && <div className="pFont text-start" dangerouslySetInnerHTML={{ __html: props.paragraph }}></div>
                        }
                    </div>
                </>
            ) : (
                <div className="card-body">
                    <h3 className="card-title">{props.title}</h3><br /><br />
                    <div className="pFont text-start" dangerouslySetInnerHTML={{ __html: props.paragraph }}></div>
                </div>
            )}
            {props.footerurl && (
                <><br />
                    <div className="card-footer">
                        <a href={props.footerurl} className="btn btn-secondary rounded-pill col-lg-12">{props.footertext}</a>
                    </div>
                </>
            )}
            {props.children}
        </div>
    );
}
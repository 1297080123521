import jQuery from "jquery";
import { usjsconfig } from "./map-config";

function isTouchEnabled() {
  return (("ontouchstart" in window)
    || (navigator.MaxTouchPoints > 0)
    || (navigator.msMaxTouchPoints > 0));
}
jQuery(function () {
  jQuery("path[id^=usjs]").each(function (i, e) {
    usaddEvent( jQuery(e).attr("id"));
  });
});
jQuery(function () {
  jQuery('#lakes').find('path').attr({'fill':usjsconfig.general.lakesFill}).css({'stroke':usjsconfig.general.lakesOutline});
});
function usaddEvent(id,relationId) {
  var _obj = jQuery("#" + id);
  var arr = id.split("");
  var _Textobj = jQuery("#" + id + "," + "#usjsvn" + arr.slice(4).join(""));
  jQuery("#" + ["visnames"]).attr({"fill":usjsconfig.general.visibleNames});
  _obj.attr({"fill":usjsconfig[id].upColor, "stroke":usjsconfig.general.borderColor});
  _Textobj.attr({"cursor": "default"});

}
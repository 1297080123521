import AboutUs from "./pages/AboutUs";
import CompanyAOP from "./pages/CompanyAOP";
import CompanyFCIC from "./pages/CompanyFCIC";
import CompanyIO from "./pages/CompanyInsureOnline";
import CompanyLonestar from "./pages/CompanyLonestar";
import CompanyPHIS from "./pages/CompanyPHIS";
import CompanyPRA from "./pages/CompanyPRA";
import CompanyPRIA from "./pages/CompanyPRIA";
import CompanyPRIS from "./pages/CompanyPRIS";
import CompanyTXR from "./pages/CompanyTXR";
import CompanyUSIC from "./pages/CompanyUSIC";
import CompanyVitality from "./pages/CompanyVitality";
import CompanyWIN from "./pages/CompanyWIN";
import CompanyRidgeRunner from "./pages/CompanyRidgeRunner";
import ContactUs from "./pages/ContactUs";
import { Home } from "./pages/Home";
import PortfolioCompanies from "./pages/PortfolioCompanies";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import CompanyKaties from "./pages/CompanyKaties";

const AppRoutes = [
    {
        index: true,
        element: <Home />,
    },{
        path: '/ContactUs',
        element: <ContactUs />
    },{
        path: '/AboutUs',
        element: <AboutUs />
    }, {
        path: '/KatiesHallmark',
        element: <CompanyKaties />
    },{
        path: '/PortfolioCompanies',
        element: <PortfolioCompanies />
    }, {
        path: '/FCIC',
        element: <CompanyFCIC />
    }, {
       path:'/AtOncePrinting',
       element: <CompanyAOP />
    },{
        path:'/PHIS',
        element: <CompanyPHIS />
    }
    //, {
    //    path:'/PRIA',
    //    element:<CompanyPRIA /> 
    // }
    ,{
        path:'/InsureOnline',
        element: <CompanyIO />
    },{
        path:'/PRA',
        element: <CompanyPRA/>
    }
    //{
    //    path:'/PRIS',
    //    element: <CompanyPRIS/>
    //}
    , {
        path:'/Vitality',
        element: <CompanyVitality />
    },{
        path:'/LonestarMGA',
        element: <CompanyLonestar />
    },{
        path:'/USIC',
        element: <CompanyUSIC />
    },{
        path:'/TexasRangerMGA',
        element: <CompanyTXR />
    },{
        path:'/WIN',
        element:<CompanyWIN />
    },{
        path:'/RidgeRunner',
        element:<CompanyRidgeRunner />
    },{
        path: '/PrivacyPolicy',
        element: <PrivacyPolicy />
    }, {
        path: '/TermsofUse',
        element: <TermsOfUse/>
    }
];

export default AppRoutes;
